import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react'

interface Props {}

export default function Video({}: Props) {
  return (
    <Flex
      as={'section'}
      flexDirection={'column'}
      align={'center'}
      w={'100%'}
      maxW={1440}
      gap={'32px'}
      px={[0, null, '20px']}
      py={['60px', null, '120px']}
    >
      <Flex flexDir={'column'} align={'center'}>
        <Text
          as={'h1'}
          fontSize={['2xl', null, '3xl']}
          fontWeight={'semibold'}
          letterSpacing={'-0.9px'}
          textAlign={'center'}
        >
          Saiba tudo sobre o{' '}
          <Text as={'span'} color={'green.300'}>
            GPT Max
          </Text>
        </Text>
        <Text fontSize={'lg'} as={'h2'} textAlign={'center'}>
          Aperte play e entenda mais sobre a ferramenta
        </Text>
      </Flex>
      <Box
        id="video"
        bgColor={'blackAlpha.200'}
        w={'100%'}
        maxW={1024}
        overflow={'hidden'}
        borderRadius={[0, null, '16px']}
      >
        <AspectRatio ratio={16 / 9}>
          <iframe
            src={`https://player.vimeo.com/video/884951959?autopause=0&loop=0&autoplay=0&muted=0&player_id=0&controls=1&app_id=58479`}
            allow="autoplay; fullscreen; picture-in-picture"
            title="GPT Max"
          />
        </AspectRatio>
      </Box>
    </Flex>
  )
}
