import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { BsWhatsapp } from 'react-icons/bs'
import WhatsAppButton from './WhatsAppButton'

interface Props {}

export default function WhatsAppLink({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'12px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      pb={['90px', null, '120px']}
      pt={['40px', 0]}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        as={'h1'}
        textAlign={'center'}
        maxW={680}
      >
        Veja como o{' '}
        <Text as={'span'} color={'green.300'}>
          GPT Max
        </Text>
        <br /> pode ajudar o seu negócio hoje!
      </Text>
      <Text fontSize={'xl'} textAlign={'center'}>
        Toque no botão abaixo e converse diretamente com nosso time de
        especialistas.
      </Text>
      <WhatsAppButton title="Falar com a equipe" />
    </Flex>
  )
}
