import { Flex, Text } from '@chakra-ui/react'

interface Props {
  title?: string
}

export default function MessageUser({ title }: Props) {
  return (
    <Flex flexDir={'column'} gap={'8px'}>
      <Flex
        bgColor={'green.300'}
        padding={'16px'}
        alignSelf={'flex-end'}
        maxW={'80%'}
        borderRadius={'14px'}
        filter={'drop-shadow(5px 4px 4px rgba(34, 32, 52, 0.60))'}
      >
        <Text color={'grey.900'}>
          Olá Pedro, boa tarde. Gostaria que você me explicasse mais sobre os
          planos.
        </Text>
      </Flex>
      <Flex
        bgColor={'green.300'}
        padding={'16px'}
        alignSelf={'flex-end'}
        maxW={'80%'}
        borderRadius={'14px'}
        filter={'drop-shadow(5px 4px 4px rgba(34, 32, 52, 0.60))'}
      >
        <Text color={'grey.900'}>Qual poderia me atender melhor?</Text>
      </Flex>
    </Flex>
  )
}
