import { Flex, Text } from '@chakra-ui/react'
import Card from './Card'
import { TbMoodSmile, TbUserUp } from 'react-icons/tb'
import { PiSealCheckBold } from 'react-icons/pi'
import Flag from 'react-flagkit'

interface Props {}

export default function Language({}: Props) {
  return (
    <Flex
      align={'center'}
      w={'100%'}
      maxW={1440}
      py={'16px'}
      px={'20px'}
      as={'section'}
      gap={'64px'}
      flexDirection={'column'}
    >
      <Text as={'h1'} fontWeight={'semibold'} fontSize={['xl', null, '3xl']}>
        E ainda
        <Text as={'span'} color={'green.300'}>
          ...
        </Text>
      </Text>

      <Flex
        flexWrap={'wrap'}
        flex={1}
        gap={'64px'}
        justify={'space-around'}
        w={'100%'}
      >
        <Card
          title="Otimize sua equipe!"
          description="Utilize o GPT Max para <br/> automatizar tarefas rotineiras e<br/> libere tempo para tarefas estratégicas."
          icon={TbUserUp}
        />
        <Card
          title="Selo de verificação"
          description="Obtenha um selo de <br/>verificação exclusivo."
          icon={PiSealCheckBold}
        />
        <Card
          title="Fácil e amigável"
          description="Plataforma intuitiva, <br/>incrivelmente simples de usar."
          icon={TbMoodSmile}
        />
      </Flex>

      <Flex
        flexDir={'column'}
        align={'center'}
        w={'100%'}
        py={['60px', null, '80px']}
        gap={'24px'}
      >
        <Text
          textTransform={'uppercase'}
          bgColor={'green.600'}
          borderRadius={'8px'}
          padding={'4px 7px'}
          fontSize={'xs'}
        >
          Internacional
        </Text>

        <Flex gap={'24px'} align={'center'}>
          <Flag country="US" size={28} />
          <Flag country="BR" size={28} />
          <Flag country="ES" size={28} />
          <Flag country="FR" size={28} />
        </Flex>
      </Flex>
    </Flex>
  )
}
