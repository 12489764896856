import { Flex, Text } from '@chakra-ui/react'
import {
  AmazonLogo,
  AwsLogo,
  GoogleLogo,
  MicrosoftLogo,
  NetflixLogo,
  OpenAILogo,
  TeslaLogo,
  UberLogo
} from '../Icon'

interface Props {}

export default function AIPartnerCompanies({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'42px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      py={['60px', null, '120px']}
    >
      <Text fontSize={'xl'} as={'h1'} textAlign={'center'} maxW={680}>
        Trabalhamos em parceria com renomadas empresas de todo o mundo para
        proporcionar a você o que há de melhor.
      </Text>
      <Flex
        w={'100%'}
        maxW={1440}
        flexWrap={'wrap'}
        justify={'center'}
        align={'center'}
        gap={'56px'}
      >
        <Flex>
          <OpenAILogo w={'180px'} h={'100%'} />
        </Flex>
        <Flex>
          <GoogleLogo w={'129px'} h={'100%'} />
        </Flex>
        <Flex>
          <MicrosoftLogo w={'166px'} h={'100%'} />
        </Flex>
        <Flex>
          <AwsLogo w={'90px'} h={'100%'} />
        </Flex>
      </Flex>
    </Flex>
  )
}
