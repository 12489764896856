import { Flex, Text } from '@chakra-ui/react'
import Card from './Card'

interface Props {}

export default function AboutUs({}: Props) {
  return (
    <Flex
      align={'center'}
      w={'100%'}
      maxW={1440}
      py={'60px'}
      px={'20px'}
      as={'section'}
      gap={'32px'}
      flexDir={'column'}
    >
      <Text
        fontSize={['xl', null, '3xl']}
        fontWeight={'semibold'}
        textAlign={'center'}
      >
        O que falam de nós
      </Text>

      <Flex
        gap={'24px'}
        align={['stretch', null, null, 'center']}
        flexDir={['column', null, null, 'row']}
      >
        <Card
          avatar="https://d1k1f4n2h095ym.cloudfront.net/avatars/joao.png"
          description="Agora tenho uma IA que atende às necessidades da minha empresa."
          name="João R."
          title="CEO"
        />
        <Flex gap={'24px'} flexDir={'column'}>
          <Card
            avatar="https://d1k1f4n2h095ym.cloudfront.net/avatars/leandro.png"
            description="O GPT Max é realmente incrível. Sua capacidade de personalização tornou meu chatbot único e eficaz, proporcionando uma experiência incrível aos meus clientes. Treinar o modelo com meus próprios dados foi fácil e o resultado superou minhas expectativas."
            name="Leandro G."
            title="Diretor de Marketing"
          />
          <Flex
            gap={'24px'}
            align={['stretch', null, null, 'flex-start']}
            flexWrap={'wrap'}
          >
            <Card
              avatar="https://d1k1f4n2h095ym.cloudfront.net/avatars/marcelo.png"
              description="GPT Max é o futuro da Inteligência Artificial."
              name="Marcelo V."
              title="Engenheiro de Software"
            />
            <Card
              avatar="https://d1k1f4n2h095ym.cloudfront.net/avatars/victor.png"
              description="Melhorou significativamente a interação com meus clientes."
              name="Victor B."
              title="CTO"
            />
          </Flex>
        </Flex>
        <Card
          avatar="https://d1k1f4n2h095ym.cloudfront.net/avatars/andre.png"
          name="Andre P."
          title="Gerente de Estoque"
          description="GPT Max simplificou a criação do meu assistente virtual."
        />
      </Flex>
    </Flex>
  )
}
