import { StripeProductsProps } from '@/interfaces/Stripe'
import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import PriceCard from './PriceCard'
import Horizontal from './horizontal'
// import PriceCardBf from './PriceCardBf'

interface Props {
  products: StripeProductsProps[]
}

export default function Plan({ products }: Props) {
  const isMobileVersion = useBreakpointValue({
    base: true,
    md: false
  })

  return (
    <Flex
      flexDir={'column'}
      gap={'64px'}
      align={'center'}
      id="plans"
      position={'relative'}
      px={'20px'}
      py={['120px']}
      w={'100%'}
      maxW={1440}
      as={'section'}
      // pl={['20px', null, '340px']}
    >
      <Flex
        position={'absolute'}
        left={'-30%'}
        width={'1059px'}
        height={'1059px'}
        filter={'blur(45px)'}
        borderRadius={'1059px'}
        background={
          'radial-gradient(50% 50% at 50% 50%, rgba(77, 249, 174, 0.40) 0%, rgba(77, 249, 174, 0.00) 100%)'
        }
        zIndex={-1}
      />

      <Text fontWeight={'semibold'} fontSize={['xl', null, '3xl']}>
        Conheça nossos planos
      </Text>
      {isMobileVersion ? (
        <Flex flexDir={'column'} gap={'24px'}>
          {products
            .filter((item) => item.metadata.slug)
            .sort(
              (a, b) =>
                a.default_price.unit_amount - b.default_price.unit_amount
            )
            .map((plan, index) => (
              <PriceCard
                itemId={index}
                plan={plan.name}
                price={plan.default_price.unit_amount}
                items={plan.metadata.description}
                comming={plan.metadata.features}
                url={`/register?plan=${plan.metadata.slug}`}
                key={index}
                slug={plan.metadata.slug}
              />
            ))}
        </Flex>
      ) : (
        <Horizontal products={products} />
      )}
    </Flex>
  )
}
