import { Flex, Image, Text } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useState } from 'react'

interface Props {}

export default function SettingsTab({}: Props) {
  const [selectedTab, setSelectedTab] = useState(0)
  const tabs = [
    {
      title: 'Crie sua base de conhecimento',
      description:
        'Crie uma base de dados própria para a sua inteligência artificial. Alimente com documentos e imagens.',
      image: 'https://d1k1f4n2h095ym.cloudfront.net/tab-1.png'
    },
    {
      title: 'Treine seu próprio agente',
      description: 'Crie um agente e defina uma personalidade exclusiva.',
      image: 'https://d1k1f4n2h095ym.cloudfront.net/tab-2.png'
    },
    {
      title: 'Compartilhe sua base com quem desejar',
      description: 'Compartilhe através de um link ou do marketshare.',
      image: 'https://d1k1f4n2h095ym.cloudfront.net/tab-3.png'
    }
    // {
    //   title: 'Adicione de um webchat em seu site',
    //   description:
    //     'Veja o seu agente de IA atendendo clientes em seu site através de um webchat.',
    //   image: 'https://d1k1f4n2h095ym.cloudfront.net/tab-1.png'
    // },
    // {
    //   title: 'Integre com Whatsapp',
    //   description:
    //     'Integre o seu agente de IA no Whatsapp da sua empresa e atenda milhares de clientes simultaneamente de forma humanizada.',
    //   image: 'https://d1k1f4n2h095ym.cloudfront.net/tab-1.png'
    // }
  ]

  return (
    <Flex
      flexDir={'column'}
      maxW={1440}
      w={'100%'}
      as={'section'}
      px={'20px'}
      display={['none', null, 'flex']}
    >
      <Text
        textAlign={'center'}
        fontSize={['2xl', null, '3xl']}
        fontWeight={'semibold'}
      >
        Conheça alguns detalhes da{' '}
        <Text as={'span'} color={'green.300'}>
          ferramenta
        </Text>{' '}
      </Text>
      <Text textAlign={'center'} fontSize={'lg'}>
        Toque nos itens para explorar cada uma das funcionalidades do GPT Max.
      </Text>

      <Flex flex={1} py={'60px'} w={'100%'}>
        <Tabs
          size="md"
          defaultIndex={0}
          variant="unstyled"
          orientation={'vertical'}
          onChange={setSelectedTab}
          align="center"
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                borderTopLeftRadius={'8px'}
                borderRightRadius={0}
                borderBottomLeftRadius={'8px'}
                borderRightWidth={0}
                borderLeftWidth={1}
                borderTopWidth={1}
                borderColor={'green.rgba100'}
                bgColor={'blackAlpha.400'}
                p={'24px'}
                _selected={{
                  borderColor: 'green.300'
                }}
                _disabled={{
                  borderColor: 'green.300'
                }}
              >
                <Flex
                  flexDir={'column'}
                  align={'flex-start'}
                  textAlign={'left'}
                  gap={'8px'}
                  w={'100%'}
                  maxW={620}
                >
                  <Text fontWeight={'semibold'}>{tab.title}</Text>
                  <Text
                    fontSize={'sm'}
                    display={['none', null, null, null, 'inline-block']}
                  >
                    {tab.description}
                  </Text>
                </Flex>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel p={0} m={0} key={tab.title} flex={1} h={'100%'}>
                <Image src={tab.image} alt={tab.title} borderRadius={'12px'} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  )
}
