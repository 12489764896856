import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { BsWhatsapp } from 'react-icons/bs'
import WhatsAppButton from './WhatsAppButton'

interface Props {}

export default function WhatsAppLinkFaq({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'12px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      pb={['90px', null, '60px']}
      pt={['40px', 0]}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        as={'h1'}
        textAlign={'center'}
        maxW={680}
      >
        Está em dúvida?
      </Text>
      <Text fontSize={'xl'} textAlign={'center'}>
        Fale diretamente com nossos especialistas no Whatsapp.
      </Text>
      <WhatsAppButton title="Falar com a equipe" />
    </Flex>
  )
}
