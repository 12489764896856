import { Badge, Divider, Flex, Link, Text } from '@chakra-ui/react'
import { Checked } from '@/component/Icon'
import { FormatMoney } from '@/utils/helpers'
import NextLink from 'next/link'
import { useContext } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

export interface PlanProps {
  plan: string
  price: number
  items: string
  comming: string
  url: string
  slug: string
  has?: boolean
  itemId: number
}

export default function PriceCard({
  plan,
  price,
  url,
  slug,
  items,
  comming,
  has = false,
  itemId
}: PlanProps) {
  const visibility = useContext(VisibilityContext)
  // const visible = visibility?.isItemVisible(`${itemId}`)

  const list = items?.split(';') ?? []
  const features = comming?.split(';') ?? []

  const formatCustom = (value: string) => {
    const [first, last] = value.split(',')
    const [currency, price] = first.split(/\s/)

    return `${currency} <span>${price}</span>,${last}`
  }

  return (
    <Flex
      tabIndex={itemId}
      as={'article'}
      flexWrap={'wrap'}
      flexDirection={['column']}
      align={'flex-start'}
      gap={'32px'}
      bg={
        'var(--Gradient-Glass, linear-gradient(155deg, #222034 -24.21%, rgba(129, 125, 167, 0.14) 18.29%, rgba(49, 47, 70, 0.87) 90.47%, #222034 105.3%, rgba(133, 128, 171, 0.11) 105.3%))'
      }
      py={'32px'}
      px={'24px'}
      borderWidth={slug === 'master' ? 2 : 0}
      borderColor={slug === 'master' ? 'rgba(77, 249, 174, 0.3)' : 'grey.600'}
      borderRadius={'32px'}
      minW={[280, null, 320]}
      flex={1}
      w={'100%'}
      _active={{
        cursor: 'grabbing'
      }}
      h={'100%'}
      justify={'space-between'}
      boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
    >
      <Flex flexDir={'column'} gap="32px" w={'100%'}>
        <Flex align="center" gap="10px">
          <Text
            as={'h1'}
            color={slug === 'free' ? 'white' : 'green.300'}
            userSelect={'none'}
            fontSize={'2xl'}
          >
            {plan}{' '}
          </Text>
          {slug === 'master' && (
            <Badge
              bgColor={'rgba(77, 249, 174, 0.10)'}
              p="5px 10px"
              color="green.300"
              borderRadius={'40px'}
              fontWeight={'normal'}
            >
              Recomendado
            </Badge>
          )}
        </Flex>

        <Flex flexDirection={'column'} gap={'12px'} userSelect={'none'}>
          <Text
            fontSize={'3xl'}
            lineHeight={'100%'}
            textStyle={'sora'}
            css={{
              span: {
                fontSize: '68px',
                fontWeight: 700
              }
            }}
            dangerouslySetInnerHTML={{
              __html:
                slug === 'enterprise'
                  ? 'Sob consulta'
                  : formatCustom(FormatMoney(`${price}`, 'pt-BR', 'BRL'))
            }}
          />

          {has ? (
            <Text
              textDecorationLine={'underline'}
              justifySelf={'flex-end'}
              textTransform={'uppercase'}
              mt={'24px'}
              fontWeight={'bold'}
              color={'grey.300'}
              fontSize={'sm'}
              cursor={'not-allowed'}
            >
              Você possui este plano
            </Text>
          ) : (
            <Link
              justifySelf={'flex-end'}
              textTransform={'uppercase'}
              mt={'24px'}
              fontWeight={'semibold'}
              as={NextLink}
              href={slug !== 'enterprise' ? url : 'https://wa.me/48988164472?text=Olá!%20Tenho%20interesse%20e%20queria%20mais%20informações%20sobre%20o%20GPT%20Max,%20por%20favor'}
              target={slug !== 'enterprise' ? '_self' : '_blank'}
              fontSize={'xs'}
              bgColor={'green.300'}
              color={'grey.900'}
              _hover={{
                color: 'grey.100',
                textDecoration: 'none',
                bgColor: 'grey.900'
              }}
              py={'16px'}
              px={'24px'}
              borderRadius={'4px'}
              w={'100%'}
              textAlign="center"
            >
              {slug !== 'enterprise'
                ? slug === 'free'
                  ? 'Experimento gratuitamente'
                  : `Contratar`
                : 'Falar com especialista'}
            </Link>
          )}
        </Flex>

        <Divider borderColor={'grey.400'} />
        <Flex gap={'20px'} flexDirection={'column'} userSelect={'none'}>
          {list.map(
            (item, index) =>
              item.length > 1 && (
                <Flex key={index} align={'center'} gap={'12px'}>
                  <Checked w={'13px'} h={'11px'} />
                  <Text fontSize={'md'}>{item}</Text>
                </Flex>
              )
          )}
          {features.length > 0 && (
            <>
              <Divider borderColor={'grey.400'} />
              <Text fontSize={'md'}>Em breve</Text>
              <Divider borderColor={'grey.400'} />
            </>
          )}
          {features.map(
            (item, index) =>
              item.length > 1 && (
                <Flex key={index} align={'center'} gap={'12px'}>
                  <Checked w={'13px'} h={'11px'} />
                  <Text fontSize={'md'}>{item}</Text>
                </Flex>
              )
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
