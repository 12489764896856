import { Flex, FlexProps, Link, Text } from '@chakra-ui/react'

interface Props extends FlexProps {
  title: string
  description: string
  url: string
}

export default function Card({ title, description, url, ...rest }: Props) {
  return (
    <Link
      href={url}
      isExternal
      transition={'transform .2s linear'}
      _hover={{
        textDecoration: 'none',
        transition: 'transform .2s linear',
        transform: 'scale(1.04)'
      }}
      flex={1}
      minW={280}
      minH={420}
      display={'flex'}
    >
      <Flex
        borderWidth={2}
        borderColor={'green.rgba100'}
        overflow={'hidden'}
        pos={'relative'}
        flexDir={'column'}
        rounded={'xl'}
        justify={'flex-end'}
        p={'32px'}
        boxShadow={'0px 25px 50px -12px rgba(0, 0, 0, 0.25)'}
        background={
          'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.10) 36.98%, rgba(0, 0, 0, 0.90) 98%), url(https://d1k1f4n2h095ym.cloudfront.net/bg-blog.jpg) no-repeat'
        }
        bgSize={'cover'}
        gap={'12px'}
      >
        <Flex className="glow" zIndex={1} {...rest} />
        <Flex
          position={'absolute'}
          zIndex={2}
          width={'1059px'}
          bottom={'-85%'}
          left={'-35%'}
          height={'1059px'}
          filter={'blur(45px)'}
          borderRadius={'1059px'}
          background={
            'radial-gradient(50% 50% at 50% 50%, rgba(77, 249, 174, 0.40) 0%, rgba(77, 249, 174, 0.00) 100%)'
          }
        />

        <Flex flexDir={'column'} gap={'12px'} pos={'relative'} zIndex={3}>
          <Text fontSize={'lg'} fontWeight={'semibold'}>
            {title}
          </Text>
          <Text fontSize={'md'}>{description}</Text>
        </Flex>
      </Flex>
    </Link>
  )
}
