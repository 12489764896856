import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'

interface IArrowProps extends ButtonProps {
  children: ReactNode
  disabled: boolean
  onClick: VoidFunction
}

function Arrow({ children, disabled, onClick, ...rest }: IArrowProps) {
  return (
    <Button
      width={'auto'}
      top={'-50px'}
      position={'absolute'}
      variant={'link'}
      disabled={disabled}
      onClick={onClick}
      cursor={'pointer'}
      opacity={disabled ? '0.25' : '1'}
      userSelect={'none'}
      {...rest}
      fontSize={'32px'}
      _hover={{
        color: 'green.300'
      }}
    >
      {children}
    </Button>
  )
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  )
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible)
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators])

  return (
    <Arrow
      disabled={disabled}
      onClick={() => {
        scrollNext()
      }}
      right={0}
    >
      <Icon as={BsArrowRightCircle} />
    </Arrow>
  )
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  )
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators])

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()} right={'52px'}>
      <Icon as={BsArrowLeftCircle} />
    </Arrow>
  )
}
