import Accordion from '@/component/Accordion'
import { Divider, Flex, Text } from '@chakra-ui/react'

interface Props {}

export default function Faq({}: Props) {
  return (
    <Flex w={'100%'} maxW={1440} flexDirection={'column'} align={'center'}>
      <Divider borderColor={'grey.400'} />
      <Flex
        pt={['80px']}
        px={'20px'}
        flexDirection={'column'}
        align={['center']}
        gap={'64px'}
        w={'100%'}
        maxW={1216}
      >
        <Text fontSize={['xl', null, '3xl']} fontWeight={'semibold'}>
          Perguntas frequentes
        </Text>

        <Accordion />
      </Flex>
    </Flex>
  )
}
