import { Flex, Image, Link, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { HiOutlineChevronDoubleDown } from 'react-icons/hi'
import WhatsApp from './WhatsApp'

interface Props {}

export default function Banner({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: '#4DF9AE'
  }

  return (
    <Flex
      w={'100%'}
      position={'relative'}
      flexDirection={'column'}
      align={'center'}
      as={'aside'}
    >
      <Flex
        position={'absolute'}
        left={'-30%'}
        width={'1059px'}
        height={'1059px'}
        filter={'blur(45px)'}
        borderRadius={'1059px'}
        background={
          'radial-gradient(50% 50% at 50% 50%, rgba(77, 249, 174, 0.40) 0%, rgba(77, 249, 174, 0.00) 100%)'
        }
      />
      <Flex
        position={'relative'}
        zIndex={2}
        px={'20px'}
        w={'100%'}
        maxW={1440}
        align={'center'}
        flexDirection={['column-reverse', null, null, 'row']}
        justify={'space-between'}
        gap={'32px'}
      >
        <Flex flexDir={'column'} gap={'24px'} py={['40px']}>
          <Text
            fontSize={['2xl', '6xl']}
            fontWeight={'semibold'}
            letterSpacing={'-1.1px'}
            maxW={[772]}
            lineHeight={['36px', '58px']}
            as={'h1'}
          >
            Crie uma{' '}
            <Text as={'span'} color={'green.300'}>
              inteligência artificial personalizada
            </Text>{' '}
            para sua empresa
          </Text>
          <Text maxW={['100%', null, 508]} fontSize={'20px'}>
            Tenha sua própria base de dados e treine seu agente com os dados da
            sua empresa de um jeito fácil e totalmente customizado.
          </Text>

          <Flex align={'center'} flexWrap={'wrap'} gap={['12px', '36px']}>
            <Link
              as={NextLink}
              href="/register?plan=free"
              style={buttonStyle}
              bgColor={'green.300'}
              color={'grey.900'}
              _hover={{
                textDecoration: 'none',
                bgColor: 'transparent',
                color: 'white'
              }}
              id="free-access-banner"
            >
              Acesse gratuitamente
            </Link>
            <Text fontSize={'sm'}>Sem precisar cadastrar cartão</Text>
          </Flex>

          <Text
            textTransform={'uppercase'}
            textStyle={'space'}
            fontWeight={'bold'}
          >
            Entenda como funciona o gptmax, <br /> assista o vídeo.
          </Text>

          <Flex>
            <Link href="#video">
              <HiOutlineChevronDoubleDown size={36} />
            </Link>
          </Flex>
        </Flex>

        <Flex
          position={'relative'}
          flex={1}
          py={['48px', null, '120px']}
          style={{ perspective: '500px' }}
          // transform={['rotate(0deg)', null, null, 'rotate(5deg)']}
          minW={[380]}
          pr={'30px'}
        >
          <Image
            src={'https://d1k1f4n2h095ym.cloudfront.net/wp-chat.png'}
            alt={'Chat'}
            position={'absolute'}
            right={0}
            top={10}
            zIndex={3}
            maxW={28}
          />
          <Image
            src={'https://d1k1f4n2h095ym.cloudfront.net/wp-wp.png'}
            alt={'Chat'}
            position={'absolute'}
            left={0}
            bottom={'240px'}
            zIndex={3}
            maxW={24}
          />
          <Image
            src={'https://d1k1f4n2h095ym.cloudfront.net/wp-mic.png'}
            alt={'Chat'}
            position={'absolute'}
            right={0}
            bottom={16}
            zIndex={3}
            maxW={24}
          />
          <WhatsApp />
        </Flex>
      </Flex>
    </Flex>
  )
}
