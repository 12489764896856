import { Button, Link } from '@chakra-ui/react'
import { BsWhatsapp } from 'react-icons/bs'

interface Props {
  title: string
}

export default function WhatsAppButton({ title }: Props) {
  return (
    <Button
      leftIcon={<BsWhatsapp size={20} />}
      as={Link}
      color={'grey.900'}
      _hover={{
        bgColor: 'green.500',
        color: 'white'
      }}
      isExternal
      href="https://wa.me/48988164472?text=Olá!%20Tenho%20interesse%20e%20queria%20mais%20informações%20sobre%20o%20GPT%20Max,%20por%20favor"
    >
      {title}
    </Button>
  )
}
