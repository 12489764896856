import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { BsWhatsapp } from 'react-icons/bs'
import WhatsAppButton from './WhatsAppButton'

interface Props {}

export default function WhatsAppLinkCompany({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'12px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      pt={['80px', null, '120px']}
      pb={['40px', null, '30px']}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        as={'h1'}
        textAlign={'center'}
      >
        Quer descobrir como o GPT Max <br /> pode ser usado dentro da sua
        empresa?
      </Text>
      <WhatsAppButton title="Falar com a equipe" />
    </Flex>
  )
}
