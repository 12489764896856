import { Box } from '@chakra-ui/react'
import {
  getItemsPos,
  ScrollMenu,
  VisibilityContext
} from 'react-horizontal-scrolling-menu'
import { ContextType } from 'react'

import useDrag from '@/Context/useDrag'
import { StripeProductsProps } from '@/interfaces/Stripe'
import PriceCard from '../PriceCard'
import { LeftArrow, RightArrow } from './Arrows'
import PriceCardBf from '../PriceCardBf'

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>

interface Props {
  products: StripeProductsProps[]
}

export default function Horizontal({ products }: Props) {
  const { dragStart, dragStop, dragMove } = useDrag()

  const handleDrag =
    ({ scrollContainer }: any) =>
    (ev: any) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  function onWheel(apiObj: scrollVisibilityApiType, ev: WheelEvent): void {
    const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

    if (isTouchpad) {
      ev.stopPropagation()
      return
    }
    if (ev.deltaY < 0) {
      //@ts-ignore
      apiObj.scrolNext()
    } else if (ev.deltaY > 0) {
      //@ts-ignore
      apiObj.scrollPrev()
    }
  }

  return (
    <Box onMouseLeave={dragStop} position={'relative'} width={'100%'}>
      <ScrollMenu
        itemClassName={'spacing'}
        scrollContainerClassName={'select-scroll'}
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={() => onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={({
            getItemById,
            scrollToItem,
            visibleItems
          }: scrollVisibilityApiType) =>
          () => {
            dragStop()
            const { center } = getItemsPos(visibleItems)
            scrollToItem(getItemById(center), 'smooth', 'start')
          }}
        options={{ throttle: 1 }}
        onMouseMove={handleDrag}
      >
        {products
          .filter((item) => item.metadata.slug)
          .sort(
            (a, b) => a.default_price.unit_amount - b.default_price.unit_amount
          )
          .map((plan, index) => (
            <PriceCard
              itemId={index}
              plan={plan.name}
              price={plan.default_price.unit_amount}
              items={plan.metadata.description}
              comming={plan.metadata.features}
              url={`/register?plan=${plan.metadata.slug}`}
              key={index}
              slug={plan.metadata.slug}
            />
          ))}
      </ScrollMenu>
    </Box>
  )
}
