import { Fragment, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Faq } from '@/dto/faq'
import { AccordionMinus, AccordionPlus } from '../Icon'

export default function Accordion() {
  const [activeIndex, setActiveIndex] = useState<number | null>(0)

  const onHeaderClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const renderedItems = Faq.map((item, index) => {
    const isActive = index === activeIndex ? 'active' : 'inactive'

    return (
      <Fragment key={index}>
        <Text
          color={'white'}
          fontWeight={'semibold'}
          fontSize={'lg'}
          className={`accordion-header ${isActive}`}
          onClick={() => onHeaderClick(index)}
        >
          {item.title}
          <Text as={'span'} fontSize={'2xl'} color={'green.200'}>
            {index === activeIndex ? (
              <AccordionMinus w={'24px'} h={'25px'} />
            ) : (
              <AccordionPlus w={'24px'} h={'25px'} />
            )}
          </Text>
        </Text>
        <Flex
          color={'rgba(255, 255, 255, 0.5)'}
          className={`accordion-content ${isActive}`}
          flexDirection={'column'}
        >
          <Text dangerouslySetInnerHTML={{ __html: item.content }} />
          {item?.items?.map((text, index) => (
            <Text key={index}>{text}</Text>
          ))}
        </Flex>
      </Fragment>
    )
  })

  return (
    <Flex flexDirection={'column'} className="accordion">
      {renderedItems}
    </Flex>
  )
}
