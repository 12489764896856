import { Flex, Image, Link, Text } from '@chakra-ui/react'

interface Props {}

export default function Cta({}: Props) {
  return (
    <Flex
      maxW={1440}
      w={'100%'}
      flexDirection={'column'}
      align={'center'}
      pos={'relative'}
      py={['60px', null, '120px']}
      px={'20px'}
    >
      <Flex
        borderWidth={1}
        borderColor={'green.rgba100'}
        bgImage={'https://d1k1f4n2h095ym.cloudfront.net/bg-light.jpg'}
        bgRepeat={'no-repeat'}
        bgSize={['cover']}
        py={'80px'}
        borderRadius={'xl'}
        px={'60px'}
        flexDirection={'column'}
        maxW={1040}
        w={'100%'}
        pos={'relative'}
        overflow={'hidden'}
      >
        <Flex flexDir={'column'} align={'center'} gap={'12px'}>
          <Text
            fontSize={['xl', null, '3xl']}
            lineHeight={'34px'}
            textAlign={'center'}
            position={'relative'}
            fontWeight={'semibold'}
            zIndex={2}
            maxW={500}
            textShadow={'#000 0 0 2px 2px'}
          >
            Participe da revolução da Inteligência Artificial hoje!
          </Text>
          <Text>Sem necessidade de cadastrar o cartão de crédito</Text>
          <Link
            href="/register?plan=free"
            bgColor={'green.400'}
            color={'grey.900'}
            py={'12px'}
            px={'24px'}
            position={'relative'}
            zIndex={2}
            rounded={'md'}
            fontWeight={'semibold'}
            _hover={{
              bgColor: 'green.200'
            }}
            fontSize={'sm'}
          >
            Experimente gratuitamente
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
