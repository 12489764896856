import { Box, Flex, Text } from '@chakra-ui/react'
import VideoVimeo from '../VideoVimeo'

interface Props {}

export default function TimeLapse({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      align={'center'}
      w={'100%'}
      maxW={1440}
      as={'section'}
      py={['60px']}
    >
      <Text
        px={'20px'}
        as={'h1'}
        fontSize={['xl', null, '3xl']}
        fontWeight={'semibold'}
      >
        Plataforma descomplicada
      </Text>
      <Text px={'20px'} textAlign={'center'}>
        A plataforma do GPT Max é extremamente acessível e totalmente intuitiva.
      </Text>
      <Box
        mt={'24px'}
        w={'100%'}
        maxW={1024}
        overflow={'hidden'}
        borderRadius={[0, null, '12px']}
        boxShadow={'#000 0px 1px 8px 0'}
      >
        <Box overflow={'hidden'}>
          <VideoVimeo id={870772140} />
        </Box>
      </Box>
    </Flex>
  )
}
