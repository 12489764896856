import { Flex } from '@chakra-ui/react'
import Meta from '@/component/Meta'
import Head from 'next/head'
import { GetServerSideProps } from 'next'
import { stripe } from '@/services/stripe'
import { StripeProductsProps } from '@/interfaces/Stripe'
import Script from 'next/script'
import Header from '@/component/Lp2023/Header'
import Banner from '@/component/Lp2023/Banner'
import Video from '@/component/Lp2023/Video'
import AICompanies from '@/component/Lp2023/AICompanies'
import DemoChat from '@/component/Lp2023/DemoChat'
import SettingsTab from '@/component/Lp2023/SettingsTab'
import AIPartnerCompanies from '@/component/Lp2023/AIPartnerCompanies'
import CardBox from '@/component/Lp2023/CardBox'
import Language from '@/component/Lp2023/Language'
import TimeLapse from '@/component/Lp2023/TimeLapse'
import Plan from '@/component/Lp2023/Plan'
import Faq from '@/component/Lp2023/Faq'
import Footer from '@/component/Footer'
import AboutUs from '@/component/Lp2023/AboutUs'
import Blog from '@/component/Lp2023/Blog'
import Youtube from '@/component/Lp2023/Youtube'
import Cta from '@/component/Lp2023/Cta'
import WhatsAppLink from '@/component/Lp2023/WhatsAppLink/WhatsAppLink'
import WhatsAppLinkFaq from '@/component/Lp2023/WhatsAppLink/WhatsAppLinkFaq'
import WhatsAppLinkCompany from '@/component/Lp2023/WhatsAppLink/WhatsAppLinkCompany'
import { Subdomain } from '@/services/subdomain'
// import HeaderBf from '@/component/Lp2023/HeaderBf'

interface Props {
  plans: StripeProductsProps[]
}

export default function Lp({ plans }: Props) {
  return (
    <>
      <Script
        defer
        id="script-gptmax"
        src="https://d1nfa9z59crrh.cloudfront.net/scripts-files/index.umd.js?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTcxLCJrbm93bGVkZ2VfYmFzZV9pZCI6MzA3M30.fO9QiMTj6qLbbRAuAnzpOoMTroNsckI9a4GjtGJRlt0"
      />

      <Head>
        <title>GPT Max | Crie sua própria inteligência artificial</title>
        <Meta
          image={`https://d1k1f4n2h095ym.cloudfront.net/filters:quality(42)/mockup.png`}
          pageTitle={'GPT Max | Crie sua própria inteligência artificial'}
          siteName={'GPT Max'}
          description={
            'Crie sua própria base de dados e treine sua inteligência artificial para  fornecer respostas às dúvidas de clientes e automatizar tarefas. Teste agora.'
          }
          keywords={
            'Conversação Personalizada, Chat GPT Max, Interação Inteligente, Recursos Avançados, Upload de Arquivos, Orientação por Textos, Controle Total, Aprendizado de IA.'
          }
          follow
          canonical={'/'}
        />
      </Head>
      <Flex flexDirection={'column'} position={'relative'}>
        {/* <HeaderBf /> */}
        <Flex
          flexDirection={'column'}
          align={'center'}
          w={'100%'}
          textStyle={'poppins'}
          overflow={'hidden'}
          position={'relative'}
        >
          <Header />
          <Banner />
          <Video />
          <AICompanies />
          <DemoChat />
          <SettingsTab />
          <AIPartnerCompanies />
          <WhatsAppLink />
          <CardBox />
          <Language />
          <WhatsAppLinkFaq />
          <TimeLapse />
          <Plan products={plans} />
          <AboutUs />
          <WhatsAppLinkCompany />
          <Blog />
          <Youtube />
          <Faq />
          <Cta />
          <Footer />
        </Flex>
      </Flex>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  let subdomain
  try {
    subdomain = await Subdomain(context.req)
    if (subdomain?.sub === 'galapagos' || subdomain?.sub === 'devgalapagos') {
      return {
        props: {},
        redirect: {
          destination: '/login',
          permanent: false // Set to `true` for permanent redirects, `false` for temporary
        }
      }
    }
  } catch (error) {}
  try {
    const { data } = await stripe.products.list({
      expand: ['data.default_price'],
      limit: 100,
      active: true
    })

    return {
      props: {
        plans: data.filter((p) => p.metadata?.slug)
      }
    }
  } catch (e) {
    // console.log(e)
    return {
      props: {
        plans: []
      },
      redirect: {
        destination: '/error',
        permanent: false
      }
    }
  }
}
