import { Flex, Text } from '@chakra-ui/react'
import { ElementType } from 'react'
import { IconType } from 'react-icons'

interface Props {
  title: string
  description: string
  icon: IconType | ElementType
}

export default function Card({ title, icon: Icon, description }: Props) {
  return (
    <Flex
      flex={1}
      flexDir={'column'}
      gap={'24px'}
      as={'article'}
      align={'center'}
      minW={280}
    >
      <Flex
        bgColor={'green.600'}
        rounded={'md'}
        w={'72px'}
        h={'72px'}
        justify={'center'}
        align={'center'}
      >
        <Icon size={32} />
      </Flex>
      <Text
        fontWeight={'semibold'}
        fontSize={'xl'}
        textAlign={'center'}
        as={'h1'}
      >
        {title}
      </Text>
      <Text
        fontSize={'sm'}
        textAlign={'center'}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Flex>
  )
}
