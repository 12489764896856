import { Flex, Text } from '@chakra-ui/react'
import {
  AmazonLogo,
  GoogleLogo,
  MicrosoftLogo,
  NetflixLogo,
  TeslaLogo,
  UberLogo
} from '../Icon'

interface Props {}

export default function AICompanies({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'42px'}
      px={'20px'}
      align={'center'}
      as={'section'}
    >
      <Text fontSize={'xl'} as={'h1'} textAlign={'center'}>
        Diversas empresas do mundo todo utilizam Inteligência Artificial
      </Text>
      <Flex
        w={'100%'}
        maxW={1440}
        flexWrap={'wrap'}
        justify={'center'}
        align={'center'}
        gap={'44px'}
      >
        <Flex>
          <TeslaLogo w={'180px'} h={'100%'} />
        </Flex>
        <Flex>
          <UberLogo w={'96px'} h={'100%'} />
        </Flex>
        <Flex>
          <GoogleLogo w={'129px'} h={'100%'} />
        </Flex>
        <Flex>
          <AmazonLogo w={'143px'} h={'100%'} />
        </Flex>
        <Flex>
          <MicrosoftLogo w={'166px'} h={'100%'} />
        </Flex>
        <Flex>
          <NetflixLogo w={'140px'} h={'100%'} />
        </Flex>
      </Flex>
    </Flex>
  )
}
