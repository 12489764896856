import { Flex, Text } from '@chakra-ui/react'

interface Props {
  title?: string
}

export default function MessageAI({ title }: Props) {
  return (
    <Flex flexDir={'column'} gap={'8px'}>
      <Flex
        bgColor={'white'}
        padding={'16px'}
        alignSelf={'flex-start'}
        maxW={'80%'}
        borderRadius={'14px'}
        filter={'drop-shadow(5px 4px 4px rgba(34, 32, 52, 0.60))'}
      >
        <Text color={'grey.900'}>Olá. Seja bem-vindo ao GPT Max!</Text>
      </Flex>
      <Flex
        bgColor={'white'}
        padding={'16px'}
        alignSelf={'flex-start'}
        maxW={'80%'}
        borderRadius={'14px'}
        filter={'drop-shadow(5px 4px 4px rgba(34, 32, 52, 0.60))'}
      >
        <Text color={'grey.900'}>
          Sou seu agente Pedro. Como posso ajudá-lo?
        </Text>
      </Flex>
    </Flex>
  )
}
