import { Box, Flex, Text } from '@chakra-ui/react'
import Card from './Card'

interface Props {}

export default function Blog({}: Props) {
  const posts = [
    {
      title: 'Como A Inteligência Artificial Está Revolucionando A Educação',
      description:
        'A inteligência artificial (IA) tem causado um grande impacto em diversos setores...',
      url: 'https://blog.dankicode.ai/?p=86'
    },
    {
      title: 'Inteligência Artificial No Trabalho',
      description:
        'Nos últimos anos, a inteligência artificial no ambiente de trabalho tem se tornado uma realidade cada vez mais presente...',
      url: 'https://blog.dankicode.ai/?p=84'
    },
    {
      title: 'A Evolução Da IA No Atendimento Ao Cliente',
      description:
        'A inteligência artificial (IA) transformou significativamente a maneira como as empresas interagem com seus clientes...',
      url: 'https://blog.dankicode.ai/?p=82'
    },
    {
      title: 'Como A IA É Utilizada Para Prever Tendências De Mercado',
      description:
        'A inteligência artificial (IA) tem desempenhado um papel cada vez mais importante no mundo dos negócios...',
      url: 'https://blog.dankicode.ai/?p=80'
    }
  ]

  return (
    <Flex
      align={'center'}
      w={'100%'}
      maxW={1440}
      py={['60px', null, '120px']}
      px={'20px'}
      as={'section'}
      gap={'64px'}
      flexDir={'column'}
    >
      <Box>
        <Text
          textAlign={'center'}
          as={'h1'}
          fontSize={['xl', null, '3xl']}
          fontWeight={'semibold'}
        >
          Conheça o nosso{' '}
          <Text as={'span'} color={'green.300'}>
            Blog
          </Text>
        </Text>
        <Text textAlign={'center'}>
          Esteja atualizado com todas as melhorias da ferramenta e descubra como
          extrair o máximo proveito dela.
        </Text>
      </Box>

      <Flex flexWrap={'wrap'} gap={'24px'} w={'100%'}>
        {posts.map((post, index) => (
          <Card
            key={index}
            title={post.title}
            description={post.description}
            url={post.url}
            transform={`rotate(${index + 9 * 30}deg)`}
            width={index + 9 * 50}
            height={index + 9 * 50}
          />
        ))}
      </Flex>
    </Flex>
  )
}
