import { Flex, Text } from '@chakra-ui/react'

interface Props {}

export default function DemoChat({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      align={'center'}
      w={'100%'}
      maxW={1440}
      as={'section'}
      py={['80px', null, '120px']}
      gap={'24px'}
      px={'20px'}
    >
      <Text
        textTransform={'uppercase'}
        bgColor={'green.300'}
        color={'grey.900'}
        borderRadius={'8px'}
        padding={'4px 7px'}
        fontSize={'xs'}
      >
        Live demo
      </Text>
      <Text
        fontWeight={'semibold'}
        as={'h1'}
        maxW={584}
        textAlign={'center'}
        fontSize={['xl', null, '2xl']}
      >
        Experimente na prática e tire suas dúvidas sobre o{' '}
        <Text as={'span'} color={'green.300'}>
          GPT MAX
        </Text>{' '}
        com nosso agente de IA.
      </Text>
      <Flex flex={1} maxW={858} w={'100%'}>
        <Flex
          w="100%"
          minH="600px"
          h={'100%'}
          id="gpt-max-webchat"
          borderWidth={1}
          borderRadius={'16px'}
          borderColor={'grey.400'}
          overflow={'hidden'}
          boxShadow={'#000 3px 2px 10px'}
        />
      </Flex>
    </Flex>
  )
}
