import { Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { IconType } from 'react-icons'

interface Props {
  icon: IconType
  title: string
  description: string
  imageElement: ReactNode
  reverse?: boolean
}

export default function Box({
  icon: Icon,
  title,
  description,
  imageElement,
  reverse = false
}: Props) {
  return (
    <Flex
      as={'article'}
      flex={1}
      bg={
        'linear-gradient(105deg, #222034 4.12%, #58537A 58.31%, #222034 98.7%)'
      }
      borderRadius={'12px'}
      position={'relative'}
      flexDirection={[`column`, null, null, `row${reverse ? '-reverse' : ''}`]}
      boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
      py={['32px', null, '45px']}
      px={['32px', null, '54px']}
      gap={'52px'}
      align={'center'}
    >
      <Flex flexDir={'column'} gap={'16px'} flex={1}>
        <Flex
          w={'62px'}
          h={'62px'}
          align={'center'}
          justify={'center'}
          bgColor={'green.600'}
          borderRadius={'16px'}
        >
          <Icon size={28} />
        </Flex>
        <Text fontWeight={'semibold'} as={'h1'} fontSize={['xl', null, '3xl']}>
          {title}
        </Text>
        <Text maxW={['100%', null, 520]}>{description}</Text>
      </Flex>

      {imageElement}
    </Flex>
  )
}
