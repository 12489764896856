import { Flex, Text } from '@chakra-ui/react'
import MessageAI from './MessageAI'
import MessageUser from './MessageUser'
import { Input } from '@/component/Form/Input'
import { MenuVoice, Send, Typing } from '@/component/Icon'

interface Props {}

export default function WhatsApp({}: Props) {
  return (
    <Flex
      borderWidth={2}
      borderColor={'grey.400'}
      borderRadius={'14px'}
      px={'14px'}
      py={'24px'}
      background={
        'linear-gradient(312deg, rgba(34, 32, 52, 0.40) -23.13%, rgba(88, 83, 122, 0.40) 52.39%, rgba(42, 39, 64, 0.40) 127.92%)'
      }
      w={'100%'}
      maxW={520}
      boxShadow={'21px 14px 14px 0px #222034'}
      flexDir={'column'}
      gap={'16px'}
      height={600}
      justify={'space-between'}
      transform={'rotateY(-10deg)'}
      pos={'relative'}
      overflow={'hidden'}
    >
      <Flex
        position={'absolute'}
        left={'-30%'}
        top={'-30%'}
        width={'800px'}
        height={'800px'}
        filter={'blur(45px)'}
        borderRadius={'800px'}
        background={
          'radial-gradient(50% 50% at 50% 50%, rgba(77, 249, 174, 0.20) 0%, rgba(77, 249, 174, 0.00) 100%)'
        }
      />
      <Flex flexDir={'column'} gap={'24px'} fontSize={'sm'}>
        <MessageAI />
        <MessageUser />
      </Flex>

      <Flex flexDir={'column'} gap={'24px'}>
        <Flex justify={'flex-end'}>
          <Typing w={'46px'} h={'100%'} />
        </Flex>
        <Flex position={'relative'} align={'center'}>
          <Input
            name="prompt"
            defaultValue={'Gostaria de saber mais |'}
            color={'grey.100'}
            bgColor={'grey.400'}
            borderColor={'grey.300'}
            boxShadow={'-4px 4px 4px 0px rgba(34, 32, 52, 0.25)'}
          />
          <Flex pos={'absolute'} right={0} gap={'12px'} px={'18px'}>
            <MenuVoice stroke={'grey.100'} />
            <Send stroke={'grey.100'} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
